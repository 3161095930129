import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { API_PATHS } from '../../helper/constants';
import { API } from '../../services/axios.service';

const initialState = {
    data: [],
    currentPage:1,
    totalPages:1,
    nextPageUri:null,
    previousPageUri:null,
    pageSize:10,
    status: 'idle',
    error: ''
}

export const fetchSubtasks = createAsyncThunk('get-subtasks/', async ({path,isQuery}) => {
    if(isQuery===true){
        const response = await API.get(API_PATHS.ALL_SUBTASKS(path));
        return {data:response.data};
    }
    const response = await API.get(path);
    return {data:response.data};
})

export const subtaskSlice = createSlice({
    name: 'subtasks',
    initialState,
    reducers: {
        resetStates: (state) => {
            state.data=[];
            state.pageSize=10;
            state.totalPages=1;
            state.currentPage=1;
            state.nextPageUri=null;
            state.previousPageUri=null;
            state.status='idle';
            state.error='';
        },
    },
    extraReducers: {
        [fetchSubtasks.pending]: (state) => {
            state.status = 'loading'
        },
        [fetchSubtasks.fulfilled]: (state, action) => {
            state.status = 'succeeded'
            const {data:{count,next, previous,results},page} = action.payload;
            state.data = results;
            state.totalPages = Math.ceil(count/state.pageSize);
            state.currentPage = page;
            state.nextPageUri=next;
            state.previousPageUri=previous;
        },
        [fetchSubtasks.rejected]: (state, action) => {
            state.status = 'failed'
        },
    }
})

// Action creators are generated for each case reducer function
export const { resetStates } = subtaskSlice.actions;
export default subtaskSlice.reducer;
