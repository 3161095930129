import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Iconify from './Iconify';


export default function LoaderDialog(props) {
    const { open, onClose, onUpdate,url } = props
    const handleClose = () => {
        if(onClose) onClose(false);
    };
    React.useEffect(()=>{
        if(open && url){
            window.location.replace(url);
        }
    },[open])
    return (
        <div>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Loading ...</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please wait a bit
                    </DialogContentText>
                    <Iconify icon={"line-md:loading-alt-loop"}/>
                </DialogContent>
                
                {/* <DialogActions>
                    <Button color='error' onClick={handleClose}>Cancel</Button>
                    <LoadingButton disabled={!updateForm.isValid} type="button" loading={updateForm.isSubmitting} onClick={updateForm.handleSubmit}>Update</LoadingButton>
                </DialogActions> */}
            </Dialog>
        </div>
    );
}