import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import { closeDrawer } from "../store/slices/DomSlice";
import useResponsive from "../hooks/useResponsive";

const NavigationListener = () => {
    const location = useLocation(); // Hook to access the current location
    const dispatch = useDispatch();
    const mdDown = useResponsive('down','md');

    useEffect(() => {
        console.log("Navigation happened:", location.pathname);
        if(mdDown){
            dispatch(closeDrawer());
        }
        // Code to execute on each navigat ion

    }, [location]); // useEffect runs whenever 'location' changes

    return null; // This component does not render anything
};

export default NavigationListener;
