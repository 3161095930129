import React from 'react';
import { Outlet } from 'react-router-dom';

// const AllProjects = React.lazy(() => import('../pages/projects/List'));
const AllSubtasks = React.lazy(() => import('../pages/subtasks/List'));
const WBSBoard = React.lazy(()=> import('../pages/wbs/Board'));
const CreateWbs = React.lazy(()=>import('../pages/wbs/CreateWbs'));
const CreateWork = React.lazy(()=>import('../pages/time-cards/Create'));
const TimeCardList = React.lazy(()=>import('../pages/time-cards/List'));
// const AddTimeCard = React.lazy(()=> import('../pages/time-cards/Create'));
const SubmittedWeeklyTimeCards = React.lazy(()=>import('../pages/time-cards/SubmittedWeeklyTotalTimecards'));
const ThisWeekTimeCards = React.lazy(()=>import('../pages/time-cards/ThisWeek'));

export const CustomerRoutes = [
    // {
    //     title: "Projects",
    //     element: <Outlet />,
    //     path: "projects",
    //     icon: "grommet-icons:projects",
    //     name: "Projects",
    //     children: [
    //         // { path: 'create', icon: "carbon:request-quote", title: "Create Project", element: <CreateProject />, name: "Create Project" },
    //         { path: 'all', icon: "carbon:request-quote", title: "All Projects", element: <AllProjects />, name: "All Projects" },
    //     ]
    // },
    {
        title: "Tasks",
        element: <Outlet />,
        path: "subtasks",
        icon: "grommet-icons:projects",
        name: "Tasks",
        children: [
            // { path: 'create', icon: "carbon:request-quote", title: "Create Project", element: <CreateProject />, name: "Create Project" },
            { path: 'all', icon: "carbon:request-quote", title: "All Tasks", element: <AllSubtasks />, name: "All Tasks" },
            { path: 'create-wbs', icon: "carbon:request-quote", title: "Create WBS", element: <CreateWbs />, name: "Create WBS" },
        ]
    },
    {
        title: "WBS",
        element: <Outlet />,
        path: "wbs",
        icon: "grommet-icons:projects",
        name: "WBS",
        children: [
            // { path: 'create', icon: "carbon:request-quote", title: "Create Project", element: <CreateProject />, name: "Create Project" },
            { path: 'board', icon: "carbon:request-quote", title: "WBS Board", element: <WBSBoard />, name: "WBS Board" },
            { path: 'create-work', icon: "carbon:request-quote", title: "Create Work", element: <CreateWork />, name: "Create Work" },
        ]
    },
    {
        title: "Time Cards",
        element: <Outlet />,
        path: "time-cards",
        icon: "grommet-icons:projects",
        name: "Time Cards",
        children: [
            // { path: 'add', icon: "carbon:request-quote", title: "Add Time Card", element: <AddTimeCard />, name: "Add Time Card" },
            { path: 'this-week', icon: "carbon:request-quote", title: "This Week Time Cards", element: <ThisWeekTimeCards />, name: "This Week Time Cards" },
            { path: 'all', icon: "carbon:request-quote", title: "All Time Cards", element: <TimeCardList />, name: "Time Cards" },
            { path: 'weekly/submitted', icon: "carbon:request-quote", title: "Submitted Time Cards", element: <SubmittedWeeklyTimeCards />, name: "Submitted Time Cards" },
        ]
    }
]