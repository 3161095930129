import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// import {ThunkMiddleware} from 'redux-thunk';

import { configureStore, combineReducers } from "@reduxjs/toolkit";

import AuthSlice from "./slices/AuthSlice";
import DomSlice from "./slices/DomSlice";
import ProjectSlice from './slices/ProjectSlice';
import TdoSlice from './slices/TdoSlice';
import UserSlice from "./slices/UserSlice";
import SubtaskSlice from './slices/SubtaskSlice';

const persistConfig = {
    key: 'root',
    storage,
    blacklist: ["users","projects","tdos","subtasks"]
}

const rootReducer = combineReducers({
    user: AuthSlice,
    dom: DomSlice,
    users: UserSlice,
    projects: ProjectSlice,
    subtasks: SubtaskSlice,
    tdos: TdoSlice
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    // middleware: [ThunkMiddleware]
});

export const persistedStore = persistStore(store)

