import { createSlice } from '@reduxjs/toolkit'

const shouldOpenDrawer=()=>{
    const screenWidth = window.screen.width;
    // return screenWidth>900;
    return false;
}

const initialState = {
    config: {
        themeMode: 'light',
        drawer_width: 280,
        isDrawerOpen: shouldOpenDrawer(),
        fontSize: "Medium"
    },
    status: 'idle',
    error: ''
}

export const domSlice = createSlice({
    name: 'domConfigs',
    initialState,
    reducers: {
        setTheme: (state, action) => {
            state.config.themeMode = ['dark', 'light'].includes(action.payload) ? action.payload : 'light';
        },
        toggleDrawer: (state) => {
            // console.log('toggling drawer');
            state.config.isDrawerOpen = !state.config.isDrawerOpen;
        },
        closeDrawer: (state) => {
            // console.log('toggling drawer');
            state.config.isDrawerOpen = false;
        },
        setFontSize: (state, action) => {
            state.config.fontSize = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { setTheme, toggleDrawer, setFontSize, closeDrawer } = domSlice.actions
export default domSlice.reducer
