import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/ScrollToTop';
import NavigationListener from './components/NavigationListener';


// ----------------------------------------------------------------------

export default function App() {
    const themeMode=useSelector(state=>state.dom.config.themeMode);
    const history = useNavigate();

    useEffect(()=>{
        if(process.env.REACT_APP_COMING_SOON==="true"){
            history('/coming-soon');
        }
    },[history])
    return (
        <ThemeProvider themeMode={themeMode}>
            <ScrollToTop/>
            <NavigationListener/>
            <Router />
        </ThemeProvider>
    );
}
