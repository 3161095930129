import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { API_PATHS } from '../../helper/constants';
import { API } from '../../services/axios.service';

const initialState = {
    data: [],
    status: 'idle',
    error: ''
}

export const fetchTdos = createAsyncThunk('get-tdos/', async () => {
    const response = await API.get(API_PATHS.TDO_LIST());
    return response.data;
})

export const tdoSlice = createSlice({
    name: 'tdos',
    initialState,
    reducers: {
        resetStates: (state) => {
            state.data=[];
            state.status='idle';
            state.error='';
        },
        setTdoStateData: (state, action) => {
            state.status = 'succeeded'
            state.data = action.payload;
        }
    },
    extraReducers: {
        [fetchTdos.pending]: (state) => {
            state.status = 'loading'
        },
        [fetchTdos.fulfilled]: (state, action) => {
            state.status = 'succeeded'
            const {data} = action.payload;
            state.data = data;
        },
        [fetchTdos.rejected]: (state, action) => {
            state.status = 'failed'
        },
    }
})

// Action creators are generated for each case reducer function
export const { resetStates, setTdoStateData } = tdoSlice.actions;
export default tdoSlice.reducer;
